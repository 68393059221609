import React, { Component, Fragment, useEffect } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import mediumZoom from "medium-zoom"

class WorkPost extends Component {
  componentDidMount() {
    mediumZoom("[data-zoomable]", {
      margin: 30
    })
  }

  render() {
    const { posts, prev, next } = this.props.data

    const {
      worksClient,
      worksCompleted,
      worksDescCn,
      worksLocation,
      worksSize,
      worksDesign,
      worksBanner,
      worksBannerText,
      worksGallerys = []
    } = posts?.project

    const renderWorksGallerys = () => {
      const result = worksGallerys.map((gallerys, index) => {
        if (!!gallerys.worksGallery && gallerys.worksGallery.length > 0) {
          return (
            <div
              className={`items-${gallerys.worksGallery.length}`}
              key={index}
            >
              {gallerys?.worksGallery.map((item, index) => {
                return (
                  <div className="img" key={index}>
                    <GatsbyImage
                      image={item?.localFile?.childImageSharp?.gatsbyImageData}
                      alt=""
                      data-zoomable
                    />
                  </div>
                );
              })}
            </div>
          );
        } else {
          return ""
        }
      })
      return result
    }

    return (
      <Layout>
        <SEO title={worksClient} />
        <Header
          activePage="works"
          color={{ bg: "transparent", text: "#000" }}
        />
        <div
          className="banner js-viewport-animation"
          data-bg-color="transparent"
          data-color="#000"
        >
          {worksBanner ? (
            <>
              <GatsbyImage
                image={worksBanner?.localFile?.childImageSharp?.gatsbyImageData}
                alt=""
                loading="eager" />
              <div
                className="workstext"
                dangerouslySetInnerHTML={{ __html: worksBannerText }}
              />
            </>
          ) : (
            ""
          )}
        </div>
        <div
          className="main-project main-project-cn  js-viewport-animation"
          data-bg-color="transparent"
          data-color="#000"
        >
          {
            worksDescCn ? (
              <div className="text01">
                <div className="wp">
                  <p
                    className="js-text-animation"
                    dangerouslySetInnerHTML={{ __html: worksDescCn }}
                  ></p>
                </div>
              </div>
            ) : ""
          }
          <div
            className="project-detail js-viewport-animation"
            data-bg-color="#fff"
            data-color="#000"
          >
            <div className="info">
              {
                !!worksClient ? (
                  <Fragment>
                    <div className="lab js-text-animation">项目名称</div>
                    <div className="val js-text-animation">{worksClient}</div>
                  </Fragment>
                ) : ""
              }
              {
                !!worksCompleted ? (
                  <Fragment>
                    <div className="lab js-text-animation">竣工时间</div>
                    <div className="val js-text-animation">{worksCompleted}</div>
                  </Fragment>
                ) : ""
              }
              {
                !!worksLocation ? (
                  <Fragment>
                    <div className="lab js-text-animation">地点</div>
                    <div className="val js-text-animation">{worksLocation}</div>
                  </Fragment>
                ) : ""
              }
              {!!worksSize ? (
                <Fragment>
                  <div className="lab js-text-animation">平米</div>
                  <div className="val js-text-animation">{worksSize}</div>
                </Fragment>
              ) : ""}
              {!!worksDesign ? (
                <Fragment>
                  <div className="lab js-text-animation">设计</div>
                  <div className="val js-text-animation">{worksDesign}</div>
                </Fragment>
              ) : ""}
              
            </div>
            <div className="pic">
              {!!worksGallerys ? renderWorksGallerys() : ""}
            </div>
            <div
              className={`pages${
                prev.databaseId == posts.databaseId ? " noprev" : ""
              }${next.databaseId == posts.databaseId ? " nonext" : ""}`}
            >
              <Link to={`/works/${prev.slug}/`} className="prev">
                <span>prev</span>
              </Link>
              <Link to={`/works/${next.slug}/`} className="next">
                <span>next</span>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

WorkPost.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default WorkPost

export const postQuery = graphql`query ($databaseId: Int!, $prev: Int, $next: Int) {
  posts: wpPost(databaseId: {eq: $databaseId}) {
    databaseId
    project {
      worksBannerText
      worksClient
      worksCompleted
      worksDescCn
      worksDescEn
      worksLocation
      worksTitleCn
      worksSize
      worksDesign
      worksTitleEn
      worksBanner {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH)
          }
        }
      }
      worksGallerys {
        worksGallery {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  prev: wpPost(databaseId: {eq: $prev}) {
    databaseId
    slug
  }
  next: wpPost(databaseId: {eq: $next}) {
    databaseId
    slug
  }
}
`
